/* Hide the focus ring until we know it's needed */

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

.user-is-tabbing button:focus,
.user-is-tabbing input:focus,
.user-is-tabbing select:focus,
.user-is-tabbing textarea:focus {
    outline: 2px solid #7aacfe !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}
